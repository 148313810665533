import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import errorImage from '../../assets/images/404.svg';
import './404.scss';
import { Helmet } from "react-helmet"

const NotFoundPage = ({ intl: { formatMessage, locale } }) => {
  const currentLocale = locale === 'en' ? '' : `/${locale}`;

  return (
    <div className="error-page-wrapper">
      <Helmet>
        <title>
          Lotteroo | 404
        </title>
      </Helmet>
      <div className="error-page-image">
        <img src={errorImage} alt={formatMessage({ id: '404Text' })} />
      </div>
      <h1>{formatMessage({ id: '404Text' })}</h1>
      <div className="error-page-button">
        <Link to={currentLocale || '/'}>{formatMessage({ id: 'Go to Homepage' })}</Link>
      </div>
    </div>
  );
};

export default injectIntl(NotFoundPage);
